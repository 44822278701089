<template>
  <div class="carousel-header-wrap" :class="{ dark: !scheme }">
    <div class="header-left" :class="{ en: lang !== 'CN' }">
      <img src="../../assets/img/header/logo.png" class="logo" alt="" />
      <span>{{ $t('common.centerName') }}</span>
    </div>
    <div class="header-right">
      <span
        :class="{
          active: lang === 'CN',
          dark: !scheme,
        }"
        @click="langSelect('CN')"
        >CN</span
      >
      <el-divider
        v-if="device.deviceInfo.type === 'pc'"
        direction="vertical"
        :class="{ dark: !scheme }"
      />
      <span
        :class="{
          active: lang === 'EN',
          dark: !scheme,
        }"
        @click="langSelect('EN')"
        >EN</span
      >
      <el-divider
        v-if="device.deviceInfo.type === 'pc'"
        direction="vertical"
        :class="{ dark: !scheme }"
      />
      <div
        v-if="device.deviceInfo.type === 'pc'"
        class="day-night-pc"
        :class="scheme ? 'day' : 'night'"
      >
        <div class="type sun" @click="schemeSelect(true)">
          <img
            :src="
              require(`../../assets/img/header/${
                scheme ? 'sun-day' : 'sun-night'
              }.png`)
            "
            alt=""
          />
        </div>
        <div class="type moon" @click="schemeSelect(false)">
          <img
            :src="
              require(`../../assets/img/header/${
                scheme ? 'moon-day' : 'moon-night'
              }.png`)
            "
            alt=""
          />
        </div>
      </div>
      <div
        v-else
        class="day-night-mobile"
        :class="scheme ? 'day' : 'night'"
        @click="schemeSelect(null)"
      >
        <img
          :src="
            require(`../../assets/img/header/${
              scheme ? 'moon-day' : 'sun-night'
            }.png`)
          "
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue'
import useDeviceStore from '@/store/device.js'

const device = useDeviceStore()

let scheme = inject('globalScheme')
let lang = inject('globalLang')

const schemeSelect = (val) => {
  if (val === scheme.value) return
  if (val === null) {
    device.setDeviceScheme(!scheme.value)
  } else {
    device.setDeviceScheme(val)
  }
}

const langSelect = (val) => {
  lang = val
  device.setDeviceLang(val)
}
</script>

<style scoped lang="less">
@import '~@/assets/styles/common.less';
.carousel-header-wrap {
  // position: absolute;
  // width: calc(100% - 48px);
  // left: 0;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: 10;
  padding: 16px 24px;
  width: 1392px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    93deg,
    rgba(255, 255, 255, 0.7) 8%,
    rgba(249, 251, 255, 0.5) 97%
  );
  backdrop-filter: blur(10px);
  opacity: 0.8;
  transition: @darktransition;
  @media screen and (max-width: @maxWidth) {
    padding: 16px 12px;
    width: calc(100% - 24px);
  }
  &.dark {
    background: rgba(23, 23, 26, 0.7);
    padding: 16px 24px;
    transition: @darktransition;
    .header-left {
      color: #fff;
    }
    @media screen and (max-width: @maxWidth) {
      padding: 16px 12px;
      width: calc(100% - 24px);
    }
  }
  .header-left {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #283464;

    &.en {
      span {
        width: 70%;
        font-size: 16px;
        line-height: 16px;
        white-space: wrap;
      }
    }
    @media screen and (max-width: @maxWidth) {
      font-size: 16px;
      &.en {
        span {
          line-height: 14px;
          width: 100%;
          white-space: wrap;
          font-size: 12px;
        }
      }
    }
    .logo {
      width: 22.35px;
      height: 32px;
      margin-right: 8px;
      @media screen and (max-width: @maxWidth) {
        width: 16.76px;
        height: 24px;
        margin-right: 2px;
      }
    }
  }
  .header-right {
    display: flex;
    align-items: center;

    :deep(.el-divider--vertical) {
      height: 12px;
      margin: 0 20px 0 12px;
      &.dark {
        background-color: rgba(255, 255, 255, 0.4);
        border-color: rgba(255, 255, 255, 0.4);
      }
    }
    span {
      height: 20px;
      line-height: 20px;
      width: 22px;
      text-align: center;
      color: #646566;
      font-size: 14px;
      cursor: pointer;
      margin-right: 8px;
      &.dark {
        color: rgba(255, 255, 255, 0.7);
      }
      // &:hover {
      //   color: rgba(42, 108, 246, 0.5);
      // }
      &.active {
        color: #2a6cf6;
        font-weight: 800;
      }
    }

    .day-night-pc {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 6px;
      width: 54px;
      height: 28px;
      transition: all 1s;
      &.day {
        background: #f5f5f5;
        .sun {
          background: #ffffff;
        }
      }
      &.night {
        background: #17171a;
        .moon {
          background: #37425a;
        }
      }
      .type {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 50%;
          height: 50%;
        }
      }
    }
    .day-night-mobile {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.day {
        background-color: #f5f5f5;
      }
      &.night {
        background-color: #262626;
      }
      img {
        width: 50%;
        height: 50%;
      }
    }
  }
}
</style>
