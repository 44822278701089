<template>
  <div class="cooperative-partner-container" :class="{ dark: !scheme }">
    <TitlePanel :title="$t('navigator.partner')" titleEn="Cooperative Partner" />
    <div class="title-notes" :class="{ dark: !scheme }">
      {{ $t('partner.desc') }}
    </div>
    <div class="cooperator-list" :class="{ dark: !scheme }">
      <div
        v-for="(item, index) in cooperatorList"
        :key="index"
        class="cooperator"
      >
        <img :src="item" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, inject } from 'vue'
import TitlePanel from '../../components/TitlePanel.vue'
import fudan from '@/assets/img/cooperativepartner/fudan.png'
import guokeda from '@/assets/img/cooperativepartner/guokeda-hz.png'
import nanjingyike from '@/assets/img/cooperativepartner/nanjingyike.png'
import nus from '@/assets/img/cooperativepartner/nus.png'
import shanghaijiaoda from '@/assets/img/cooperativepartner/shanghaijiaoda.png'
import shanghaikeji from '@/assets/img/cooperativepartner/shanghaikeji.png'
import tongji from '@/assets/img/cooperativepartner/tongji.png'
import xihu from '@/assets/img/cooperativepartner/xihu.png'
import zheda from '@/assets/img/cooperativepartner/zheda.png'
import nongkeyuan from '@/assets/img/cooperativepartner/nongkeyuan-zj.png'
import zhongkeyuan from '@/assets/img/cooperativepartner/zhongkeyuan-sz.png'
import zhongshandaxue from '@/assets/img/cooperativepartner/zhongshandaxue.png'

const scheme = inject('globalScheme')
const cooperatorList = [
  zheda,
  xihu,
  zhongshandaxue,
  shanghaijiaoda,
  fudan,
  tongji,
  nanjingyike,
  shanghaikeji,
  nus,
  nongkeyuan,
  zhongkeyuan,
  guokeda,
]
</script>

<style scoped lang="less">
.cooperative-partner-container {
  width: 1244px;
  padding: 86px 98px 194px;
  margin: 0 auto;
  position: relative;
  background-image: url('../../../assets/img/cooperativepartner/bg.png'); //浅色模式
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &.dark {
    background-image: url('../../../assets/img/cooperativepartner/bg_dark.png'); //深色模式
  }
  .title-notes {
    font-size: 20px;
    line-height: 32px;
    color: #283464;
    margin: 10px 0 90px;
    &.dark {
      color: rgba(255, 255, 255, 0.7);
    }
  }
  .cooperator-list {
    padding: 80px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    background: linear-gradient(
      106deg,
      rgba(255, 255, 255, 0.7) 8%,
      rgba(249, 251, 255, 0.5) 97%
    );
    border-radius: 16px;
    backdrop-filter: blur(10px);
    &.dark {
      background: linear-gradient(
        106deg,
        rgba(62, 71, 91, 0.6) 2%,
        rgba(58, 69, 90, 0.3) 97%
      );
    }
    .cooperator {
      margin: 0 26px 18px 0;
      width: 190px;
      height: 56px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      overflow: hidden;
      img {
        height: 100%;
        background-color: #ffffff;
      }
    }
  }
}
</style>
