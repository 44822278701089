<template>
  <div
    class="research-direction-container"
    :class="scheme ? 'light' : 'dark'"
    @mouseenter="animateRender"
  >
    <el-row class="research-row-class">
      <TitlePanel
        id="researchRef"
        :title="$t('navigator.researchDirection')"
        titleEn="Research Direction"
      />
    </el-row>
    <div
      v-if="animateStep3"
      class="research-direction-list animate__animated animate__fadeInUp"
    >
      <div
        v-for="(item, index) in researchDirection"
        :key="index"
        class="research-direction"
        :style="item.style"
      >
        <div
          class="research-name"
          :class="{ dark: !scheme, en: lang !== 'CN' }"
        >
          {{ item.name }}
        </div>
        <div class="research-icon">
          <img :src="item.icon" alt="" />
        </div>
      </div>
    </div>
    <div v-else class="research-direction-list-empty"></div>
    <div
      v-if="animateStep2"
      class="arrow-group-wrap animate__animated animate__fadeInUp"
    >
      <div class="arrow-group"></div>
    </div>
    <div v-else class="arrow-group-empty"></div>
    <div
      v-if="animateStep1"
      id="researchContentRef"
      class="research-content animate__animated animate__fadeInUp"
      :class="{ dark: !scheme }"
    >
      <div
        v-for="(base, index) in researchBase"
        :key="index"
        class="reasearch-base"
        :class="{ ['base-' + index]: true, en: lang !== 'CN' }"
      >
        <div class="name" :class="{ dark: !scheme, en: lang !== 'CN' }">
          {{ base.name }}
        </div>
        <div class="desc" :class="{ dark: !scheme, en: lang !== 'CN' }">
          {{ base.desc }}
        </div>
      </div>
      <div class="rule-text" :class="{ en: lang !== 'CN' }">
        {{ $t('researchDirection.centerDogma') }}
      </div>
      <div class="center-rule">
        <img :src="scheme ? rulecenter : rulecenterDark" alt="" />
      </div>
    </div>
    <div v-else class="research-content-empty"></div>
  </div>
</template>

<script setup>
import TitlePanel from '../../components/TitlePanel.vue'
import { onMounted, ref, onUnmounted, inject, computed } from 'vue'
import direction1 from '@/assets/img/researchdirection/direction-1.png'
import direction2 from '@/assets/img/researchdirection/direction-2.png'
import direction3 from '@/assets/img/researchdirection/direction-3.png'
import direction4 from '@/assets/img/researchdirection/direction-4.png'
import direction5 from '@/assets/img/researchdirection/direction-5.png'
import direction6 from '@/assets/img/researchdirection/direction-6.png'
import rulecenter from '@/assets/img/researchdirection/center-rule.png'
import rulecenterDark from '@/assets/img/researchdirection/center-rule_dark.png'
import { useI18n } from 'vue-i18n'
const i18n = useI18n()

const scheme = inject('globalScheme')
const lang = inject('globalLang')

const researchDirection = computed(() => {
  return [
    {
      name: i18n.t('researchDirection.direction1'),
      icon: direction1,
      style: { marginBottom: '-190px' },
    },
    {
      name: i18n.t('researchDirection.direction2'),
      icon: direction2,
      style: { marginBottom: '-20px' },
    },
    {
      name: i18n.t('researchDirection.direction3'),
      icon: direction3,
      style: { marginBottom: '70px' },
    },
    {
      name: i18n.t('researchDirection.direction4'),
      icon: direction4,
      style: { marginBottom: '70px' },
    },
    {
      name: i18n.t('researchDirection.direction5'),
      icon: direction5,
      style: { marginBottom: '-20px' },
    },
    {
      name: i18n.t('researchDirection.direction6'),
      icon: direction6,
      style: { marginBottom: '-190px' },
    },
  ]
})
const researchBase = computed(() => {
  return [
    {
      name: i18n.t('researchDirection.researchBaseName2.name'),
      desc: i18n.t('researchDirection.researchBaseName2.desc'),
    },
    {
      name: i18n.t('researchDirection.researchBaseName1.name'),
      desc: i18n.t('researchDirection.researchBaseName1.desc'),
    },
    {
      name: i18n.t('researchDirection.researchBaseName3.name'),
      desc: i18n.t('researchDirection.researchBaseName3.desc'),
    },
  ]
})
const animateStep1 = ref(false)
const animateStep2 = ref(false)
const animateStep3 = ref(false)

const animateRender = () => {
  function stepOne() {
    setTimeout(() => {
      animateStep1.value = true
      stepTwo()
    }, 0)
  }
  function stepTwo() {
    setTimeout(() => {
      animateStep2.value = true
      stepThree()
    }, 300)
  }
  function stepThree() {
    setTimeout(() => {
      animateStep3.value = true
    }, 0)
  }
  stepOne()
}

// // 滚动监听
const onScroll = () => {
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop
  if (scrollTop >= 0 && scrollTop < 1800) {
    animateRender()
    window.removeEventListener('scroll', onScroll)
  }
}

onMounted(() => {
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop
  if (!scrollTop) animateRender()
  window.addEventListener('scroll', onScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>

<style scoped lang="less">
@import '~@/assets/styles/common.less';
.research-direction-container {
  width: 1440px;
  padding-top: 86px;
  margin: 0 auto;

  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: relative;
  &.light {
    background-image: linear-gradient(
      180deg,
      #ffffff 0%,
      #d2d9f2 100%
    ); // 浅色模式
    // transition: @darktransition;
  }
  &.dark {
    background-color: #17171a; // 深色模式
    // transition: @darktransition;
  }

  .research-row-class {
    margin: 0px 0px 0px 98px;
    padding-bottom: 10px;
  }

  .research-direction-list {
    display: flex;
    width: 1240px;
    justify-content: space-between;
    margin: 0 auto;
    height: 100%;
    .research-direction {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 200px;
      .research-name {
        font-size: 24px;
        line-height: 40px;
        text-align: center;
        color: #283464;
        margin-bottom: 5px;
        &.en {
          line-height: 30px;
          font-size: 18px;
        }
        &.dark {
          color: #ffffff;
        }
      }
      .research-icon {
        width: 120px;
        height: 120px;
        overflow: hidden;
        border-radius: 60px;
        img {
          width: 100%;
        }
      }
      .arrow {
        margin-top: 10px;
      }
    }
  }
  .research-direction-list-empty {
    height: 235px;
  }
  .arrow-group-wrap {
    margin: 0 auto;
    width: 987.75px;
    height: 154.99px;
    opacity: 0.5;
    margin-bottom: -160px;
    // margin-bottom: -10vw;
    .arrow-group {
      position: absolute;
      top: -40px;
      left: 0;
      right: 0;
      height: 100%;
      background-image: url('../../../assets/img/researchdirection/arrow-bg.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
    }
  }
  .arrow-group-empty {
    width: 987.75px;
    height: 154.99px;
    margin-bottom: -160px;
  }
  .research-content {
    height: 472px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    position: relative;
    background-image: url('../../../assets/img/researchdirection/bg-circle.png'); // 浅色模式
    &.dark {
      background-image: url('../../../assets/img/researchdirection/bg-circle_dark.png'); // 深色模式
    }
    .reasearch-base {
      position: absolute;
      width: 280px;
      z-index: 10;
      &.base-0 {
        left: 50%;
        bottom: 328px;
        transform: translateX(-50%);
        &.en {
          bottom: 280px;
        }
      }
      &.base-1 {
        left: 90px;
        bottom: 122px;
      }
      &.base-2 {
        right: 90px;
        bottom: 122px;
      }
      .name {
        font-size: 28px;
        font-weight: bold;
        line-height: 40px;
        text-align: center;
        color: #283464;
        margin-bottom: 8px;
        font-family: Alibaba-PuHuiTi-Bold;
        &.en {
          font-size: 22px;
        }
        &.dark {
          color: #ffffff;
        }
      }
      .desc {
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #646566;
        &.en {
          font-size: 12px;
        }
        &.dark {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
    .rule-text {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 164px;
      font-size: 24px;
      font-weight: 1000;
      line-height: 32px;
      color: #2a6cf6;
      border-radius: 100px;
      background: #ffffff;
      padding: 4px 32px;
      font-family: Alibaba-PuHuiTi-Bold;
      &.en {
        font-size: 16px;
      }
    }
    .center-rule {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 52px;
      height: 80px;
      img {
        height: 100%;
      }
    }
  }
  .research-content-empty {
    height: 472px;
    width: 100%;
  }
}
</style>
