<template>
  <div
    :class="
      scheme
        ? 'dataset-container-mobile dataset-container-mobile-light'
        : 'dataset-container-mobile dataset-container-mobile-dark'
    "
  >
    <TitlePanel :title="$t('navigator.massiveDataset')" titleEn="Massive Dataset"> </TitlePanel>
    <div class="align-horizontal-center button-group-mobile">
      <el-button
        :class="
          currectClick === 1
            ? scheme
              ? 'go-button-activate-mobile'
              : 'go-button-activate-mobile dark'
            : 'go-button-no-activate-mobile'
        "
        style="border-radius: 100px"
        @click="buttonClick(1)"
      >
      {{ $t('massiveDataset.sequence.title') }}
      </el-button>
      <el-button
        :class="
          currectClick === 2
            ? scheme
              ? 'go-button-activate-mobile'
              : 'go-button-activate-mobile dark'
            : 'go-button-no-activate-mobile'
        "
        style="border-radius: 100px"
        @click="buttonClick(2)"
      >
      {{ $t('massiveDataset.omics.title') }}
      </el-button>
      <el-button
        :class="
          currectClick === 3
            ? scheme
              ? 'go-button-activate-mobile'
              : 'go-button-activate-mobile dark'
            : 'go-button-no-activate-mobile'
        "
        style="border-radius: 100px"
        @click="buttonClick(3)"
      >
      {{ $t('massiveDataset.interaction.title') }}
      </el-button>
      <el-button
        :class="
          currectClick === 4
            ? scheme
              ? 'go-button-activate-mobile'
              : 'go-button-activate-mobile dark'
            : 'go-button-no-activate-mobile'
        "
        style="border-radius: 100px"
        @click="buttonClick(4)"
      >
      {{ $t('massiveDataset.literature.title') }}
      </el-button>
    </div>

    <div v-if="currectClick === 1" class="layer-0">
      <div class="align-horizontal-center text-content-mobile">
        <div class="align-vertical-center-space-between text-content-1-mobile">
          <p :class="{ dark: !scheme, en: lang !== 'CN' }">{{ $t('massiveDataset.sequence.desc1') }}</p>
          <div class="align-horizontal-center-space-between">
            <div class="align-vertical-center">
              <div>
                <span class="text-data-mobile" :class="{ dark: !scheme }">6</span>
                <span class="text-unit-mobile" :class="{ dark: !scheme }">{{ $t('massiveDataset.sequence.unit') }}</span>
              </div>
              <p :class="{ dark: !scheme }"><b>{{ $t('massiveDataset.sequence.desc2') }}</b></p>
            </div>
            <div class="align-vertical-center">
              <div>
                <span class="text-data-mobile" :class="{ dark: !scheme }">15</span>
                <span class="text-unit-mobile" :class="{ dark: !scheme }">TB</span>
              </div>
              <p :class="{ dark: !scheme }"><b>{{ $t('massiveDataset.sequence.desc3') }}</b></p>
            </div>
          </div>
        </div>
      </div>
      <div class="align-horizontal-center img-content-mobile">
        <img :src="sequence_data" alt="" />
      </div>
    </div>

    <div v-if="currectClick === 2" class="layer-0">
      <div class="align-horizontal-center text-content-mobile">
        <div class="align-vertical-center text-content-1-mobile">
          <ul class="line-style-mobile" :class="{ dark: !scheme, en: lang !== 'CN' }">
            <li>{{ $t('massiveDataset.omics.desc1') }}</li>
            <li>{{ $t('massiveDataset.omics.desc2') }}</li>
          </ul>
        </div>
      </div>
      <div class="align-horizontal-center img-content-mobile">
        <div class="img-content-omics-mobile" :class="{ dark: !scheme }">
          <div class="align-vertical-center" style="padding: 40px">
            <img :src="omics_data_image" alt="" />
            <img :src="omics_data_legend" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="currectClick === 3" class="layer-0">
      <div class="align-horizontal-center text-content-mobile">
        <div class="align-vertical-center text-content-1-mobile">
          <ul class="line-style-mobile" :class="{ dark: !scheme, en: lang !== 'CN' }">
            <li>{{ $t('massiveDataset.interaction.desc1') }}</li>
            <li>{{ $t('massiveDataset.interaction.desc2') }}</li>
            <li>{{ $t('massiveDataset.interaction.desc3') }}</li>
          </ul>
        </div>
      </div>
      <div class="align-horizontal-center img-content-mobile">
        <WordChartMobile />
      </div>
    </div>

    <div v-if="currectClick === 4" class="layer-0">
      <div class="align-horizontal-center text-content-mobile">
        <div class="align-vertical-center text-content-1-mobile">
          <ul class="line-style-mobile" :class="{ dark: !scheme, en: lang !== 'CN' }">
            <li>{{ $t('massiveDataset.literature.desc1') }}</li>
            <li>{{ $t('massiveDataset.literature.desc2') }}</li>
            <li>{{ $t('massiveDataset.literature.desc3') }}</li>
            <li>{{ $t('massiveDataset.literature.desc4') }}</li>
          </ul>
        </div>
      </div>
      <div class="align-horizontal-center img-content-mobile">
        <img class="img-content-literature-mobile" :src="literature_data" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import TitlePanel from '../../components/TitlePanel.vue'
import WordChartMobile from '../../components/WordChart_mobile.vue'
import { defineComponent, onMounted, ref, onBeforeUnmount, inject } from 'vue'

export default defineComponent({
  name: 'Dataset',
  components: { TitlePanel, WordChartMobile },
})
</script>

<script setup>
const timerId = ref(null)
const currectClick = ref(1)

const scheme = inject('globalScheme')
const lang = inject('globalLang')

const sequence_data = require('@/assets/img/dataset/sequence_data.png')
const literature_data = require('@/assets/img/dataset/literature_data.png')
const omics_data_image = require('@/assets/img/dataset/omics_data_image.png')
const omics_data_legend = require('@/assets/img/dataset/omics_data_legend.png')

const buttonClick = (value = 1) => {
  clearTimeout(timerId.value)
  currectClick.value = value
  startTimer()
}

function buttonCircle() {
  if (currectClick.value === 4) {
    currectClick.value = 1
  } else {
    currectClick.value++
  }
}

function startTimer() {
  clearTimeout(timerId.value)
  timerId.value = setTimeout(() => {
    startTimer(), buttonCircle()
  }, 5000)
}

onMounted(() => {
  startTimer()
})

onBeforeUnmount(() => {
  if (timerId.value !== null) {
    clearTimeout(timerId.value)
    timerId.value = null
  }
})
</script>

<style scoped lang="less">
.dataset-container-mobile-light {
  background-image: radial-gradient(
      ellipse 600px 500px at 40% 80%,
      rgba(64, 158, 255, 0.2) 3%,
      rgba(229, 242, 255, 0.1) 30%
    ),
    radial-gradient(
      ellipse farthest-corner at 100% 100%,
      rgba(64, 158, 255, 0.3) 3%,
      rgba(229, 242, 255, 0.2) 30%
    );
  background-color: #ffffff;
}
.dataset-container-mobile-dark {
  background-image: radial-gradient(
      ellipse 600px 500px at 40% 80%,
      #203b66 3%,
      #17171a00 20%
    ),
    radial-gradient(
      ellipse farthest-corner at 100% 100%,
      #203b66 3%,
      #17171a00 40%
    );
  background-color: #17171a;
}
.dataset-container-mobile {
  padding: 48px 5% 56px;
  overflow: hidden;
  @media screen and (max-width: 350px) {
    padding: 48px 1% 56px;
  }

  .align-horizontal-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .align-horizontal-center-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .align-vertical-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .align-vertical-center-space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 80%;
  }

  .button-group-mobile {
    margin: 40px 0px 20px 0px;
  }

  .top-bottom-margin-20 {
    margin: 20px 0;
  }

  .text-content-mobile {
    height: 300px;
    @media screen and (max-width: 430px) {
      height: 350px;
    }
    .text-content-1-mobile {
      width: 90%;

      p {
        opacity: 1;
        font-family: AlibabaPuHuiTi;
        font-size: 18px;
        font-weight: normal;
        line-height: 20px;
        letter-spacing: 0em;
        font-variation-settings: 'opsz' auto;
        font-feature-settings: 'kern' on;
        color: #283464;
        z-index: 0;
        &.en {
          font-size: 15px;
        }
        &.dark {
          color: #ffffff;
        }
        @media screen and (max-width: 375px) {
          font-size: 14px;
          &.en {
            font-size: 12px;
          }
        }
      }

      .text-data-mobile {
        opacity: 1;
        font-family: Alibaba-PuHuiTi-Bold;
        font-size: 90px;
        font-weight: 800;
        line-height: 32px;
        letter-spacing: -0.03em;
        font-variation-settings: 'opsz' auto;
        color: #283464;
        &.dark {
          color: #ffffff;
        }
        @media screen and (max-width: 415px) {
          font-size: 70px;
        }
      }

      .text-unit-mobile {
        opacity: 1;
        font-family: Alibaba-PuHuiTi-Bold;
        font-size: 24px;
        font-weight: 800;
        line-height: normal;
        letter-spacing: -0.03em;
        font-variation-settings: 'opsz' auto;
        font-feature-settings: 'kern' on;
        color: #283464;
        &.dark {
          color: #ffffff;
        }
        @media screen and (max-width: 415px) {
          font-size: 18px;
        }
      }

      .line-style-mobile {
        opacity: 1;
        font-family: AlibabaPuHuiTi;
        font-size: 18px;
        font-weight: normal;
        line-height: 22px;
        letter-spacing: 0em;
        font-variation-settings: 'opsz' auto;
        color: #283464;
        &.en {
          font-size: 15px;
        }
        &.dark {
          color: #ffffff;
        }
        padding-inline-start: 20px;
        @media screen and (max-width: 375px) {
          font-size: 14px;
          &.en {
            font-size: 12px;
          }
        }
        li {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .img-content-mobile {
    height: 320px;
    @media screen and (max-width: 415px) {
      height: 270px;
    }
    .img-content-omics-mobile {
      display: flex;
      width: 270px;
      height: 266px;
      border: none;
      border-radius: 28px;
      background: linear-gradient(
        134deg,
        rgba(255, 255, 255, 0.4) 3%,
        rgba(255, 255, 255, 0.85) 94%
      );
      opacity: 1;
      &.dark {
        background: linear-gradient(
          134deg,
          rgba(255, 255, 255, 0.8) 3%,
          rgba(255, 255, 255, 1) 94%
        );
      }

      img {
        height: auto;
        width: 100%;
      }
    }

    img {
      margin: 20px;
      height: auto;
      width: 90%;
    }

    .img-content-literature-mobile {
      margin: 20px;
      height: auto;
      width: 70%;
    }
  }
  .layer-0 {
    animation-name: anim-layer-0;
    animation-timing-function: linear;
    animation-duration: 500ms;
    animation-fill-mode: both;
    animation-delay: 0s;
  }

  @keyframes anim-layer-0 {
    0% {
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}

:deep(.go-button-activate-mobile) {
  color: #ffffff;
  border: none;
  background: linear-gradient(123deg, #598fff 10%, #1d62f1 98%);
  box-shadow: 4px 4px 10px 0px rgba(42, 108, 246, 0.35);
  padding: 8px 14px; /* 自定义内边距 */
  width: 96px; /* 自定义宽度 */
  height: 40px; /* 维持按钮原始高度 */
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  @media screen and (max-width: 415px) {
    font-size: 14px;
    padding: 6px 10px; /* 自定义内边距 */
    width: 76px; /* 自定义宽度 */
    height: 34px; /* 维持按钮原始高度 */
  }
}

:deep(.go-button-activate-mobile:hover) {
  color: #ffffff;
  border: none;
  background: linear-gradient(123deg, #598fff 10%, #1d62f1 98%);
  box-shadow: 4px 4px 10px 0px rgba(42, 108, 246, 0.35);
}

:deep(.go-button-no-activate-mobile) {
  color: #646566;
  background: none;
  border: none;
  padding: 8px 14px; /* 自定义内边距 */
  width: 96px; /* 自定义宽度 */
  height: 40px; /* 维持按钮原始高度 */
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0em;
  &.dark {
    color: #ffffffb3;
  }
  @media screen and (max-width: 415px) {
    font-size: 14px;
    padding: 6px 10px; /* 自定义内边距 */
    width: 76px; /* 自定义宽度 */
    height: 34px; /* 维持按钮原始高度 */
  }
}

:deep(.go-button-no-activate-mobile:hover) {
  color: #2a6cf6;
  background: none;
  border: none;
}
</style>
