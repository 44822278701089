<template>
  <div class="research-direction-container" :class="scheme ? 'light' : 'dark'" @mouseenter="animateRender">
    <TitlePanel
      :title="$t('navigator.researchDirection')"
      titleEn="Research Direction"
      style="margin-bottom: 50px"
    />
    <div
      v-if="animateStep3"
      class="research-direction-list animate__animated animate__fadeInUp"
    >
      <div
        v-for="(item, index) in researchDirection"
        :key="index"
        class="research-direction"
        :style="item.style"
      >
        <div class="research-icon">
          <img :src="item.icon" alt="" />
        </div>
        <div class="research-name" :class="{ dark: !scheme, en: lang !== 'CN' }" :title="item.name">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div v-else class="research-direction-list-empty"></div>
    <div
      v-if="animateStep2"
      class="arrow-wrap animate__animated animate__fadeInUp"
    >
      <div v-for="(item, index) in 3" :key="index" class="arrow" :class="`arrow-${item}`">
        <img :src="arrow" alt="" />
      </div>
    </div>
    <div v-else class="arrow-wrap-empty"></div>
    <div
      v-show="animateStep1"
      class="research-content animate__animated animate__fadeInUp"
      :class="{ dark: !scheme }"
    >
      <div class="reasearch-base-wrap">
        <div
          v-for="(base, index) in researchBase"
          :key="index"
          class="reasearch-base"
          :style="base.style"
          @click="directionTips(base)"
        >
          <div class="name" :class="{ dark: !scheme }" :title="base.name">{{ base.name }}</div>
          <div class="desc">
            <el-icon color="#8892b1" size="16"><CaretRight /></el-icon>
          </div>
        </div>
      </div>
      <div class="rule-text" :title="$t('researchDirection.centerDogma')">{{ $t('researchDirection.centerDogma') }}</div>
      <div class="center-rule">
        <img :src="scheme ? rulecenter : rulecenterDark" alt="" />
      </div>
    </div>
    <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      width="80%"
      align-center
      center
      :show-close="false"
      :class="{ 'dialog-dark': !scheme }"
    >
      <span class="dialog-content">
        {{ dialogDesc }}
      </span>
      <template #footer>
        <div class="dialog-footer">
          <div class="confirm-btn" @click="dialogVisible = false">{{ $t('researchDirection.gotIt') }}</div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import TitlePanel from '../../components/TitlePanel.vue'
import { onMounted, ref, onUnmounted, inject, computed } from 'vue'
import direction1 from '@/assets/img/researchdirection/direction-1.png'
import direction2 from '@/assets/img/researchdirection/direction-2.png'
import direction3 from '@/assets/img/researchdirection/direction-3.png'
import direction4 from '@/assets/img/researchdirection/direction-4.png'
import direction5 from '@/assets/img/researchdirection/direction-5.png'
import direction6 from '@/assets/img/researchdirection/direction-6.png'
import arrow from '@/assets/img/researchdirection/arrow-single.png'
import rulecenter from '@/assets/img/researchdirection/center-rule.png'
import rulecenterDark from '@/assets/img/researchdirection/center-rule_dark.png'
import { CaretRight } from '@element-plus/icons-vue'
import { useI18n } from 'vue-i18n';
const i18n = useI18n();

const scheme = inject('globalScheme')
const lang = inject('globalLang')

const researchDirection = computed(() => {
  return [
    {
      name: i18n.t("researchDirection.direction1"),
      icon: direction1
    },
    {
      name: i18n.t("researchDirection.direction2"),
      icon: direction2
    },
    {
      name: i18n.t("researchDirection.direction3"),
      icon: direction3
    },
    {
      name: i18n.t("researchDirection.direction4"),
      icon: direction4
    },
    {
      name: i18n.t("researchDirection.direction5"),
      icon: direction5
    },
    {
      name: i18n.t("researchDirection.direction6"),
      icon: direction6
    }
  ]
})
const researchBase = computed(() => {
  return [
    {
      name: i18n.t("researchDirection.researchBaseName1.name"),
      desc: i18n.t("researchDirection.researchBaseName1.desc"),
      style: { marginTop: '32px' },
    },
    {
      name: i18n.t("researchDirection.researchBaseName2.name"),
      desc: i18n.t("researchDirection.researchBaseName2.desc"),
      style: {},
    },
    {
      name: i18n.t("researchDirection.researchBaseName3.name"),
      desc: i18n.t("researchDirection.researchBaseName3.desc"),
      style: { marginTop: '32px' },
    }
  ]
})
const dialogVisible = ref(false)
const dialogTitle = ref('')
const dialogDesc = ref('')
const directionTips = (item) => {
  dialogTitle.value = item.name
  dialogDesc.value = item.desc
  dialogVisible.value = true
}

const animateStep1 = ref(false)
const animateStep2 = ref(false)
const animateStep3 = ref(false)

const animateRender = () => {
  function stepOne() {
    setTimeout(() => {
      animateStep1.value = true
      stepTwo()
    }, 0)
  }
  function stepTwo() {
    setTimeout(() => {
      animateStep2.value = true
      stepThree()
    }, 300)
  }
  function stepThree() {
    setTimeout(() => {
      animateStep3.value = true
    }, 0)
  }
  stepOne()
}

// // 滚动监听
const onScroll = () => {
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop
  if (scrollTop > 0 && scrollTop < 1500) {
    animateRender()
    window.removeEventListener('scroll', onScroll)
  }
}

onMounted(() => {
  window.addEventListener('scroll', onScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>

<style scoped lang="less">
@import '~@/assets/styles/common.less';
.research-direction-container {
  padding: 48px 32px 28px;
  margin: 0 auto;
  background-size: 280% 77%;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  position: relative;
  &.light {
    background-image: url('../../../assets/img/researchdirection/bg-circle.png'),
      linear-gradient(180deg, #ffffff 0%, #d2d9f2 100%);
  }
  &.dark {
    background-image: url('../../../assets/img/researchdirection/bg-circle_dark.png'),
      linear-gradient(180deg, #17171a 0%, #17171a 100%);
    background-color: #17171a;
  }
  .research-direction-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    .research-direction {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 32%;
      .research-name {
        margin: 10px 0 32px;
        font-size: 16px;
        text-align: center;
        color: #283464;
        text-overflow: hidden;
        overflow: hidden;
        white-space: nowrap;
        &.en {
          width: 150px;
          text-overflow: ellipsis;
          @media screen and (max-width: 430px) {
            width: 100px;
            font-size: 14px;
          }
        }
        &.dark {
          color: #ffffff;
        }
      }
      .research-icon {
        width: 80px;
        height: 80px;
        overflow: hidden;
        border-radius: 40px;
        img {
          width: 100%;
        }
      }
      .arrow {
        margin-top: 10px;
      }
    }
  }
  .research-direction-list-empty {
    width: 100%;
    height: 286px;
  }
  .arrow-wrap {
    width: 100%;
    display: flex;
    justify-content: space-around;
    .arrow {
      opacity: 0.5;
      margin-bottom: 16px;
    }
    .arrow-1 {
      transform: rotate(-15deg);
      margin-top: 12px;
    }
    .arrow-2 {
      transform: rotate(-7deg);
    }
    .arrow-3 {
      transform: rotate(1deg);
      margin-top: 12px;
    }
  }
  .arrow-wrap-empty {
    height: 52px;
    width: 100%;
  }
  .research-content {
    position: relative;
    width: 100%;
    .reasearch-base-wrap {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin: 32px 0 82px;
      .reasearch-base {
        display: flex;
        justify-content: center;
        width: 120px;
        .name {
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          color: #283464;
          font-family: Alibaba-PuHuiTi-Bold;
          .fontOverflow();
          &.dark {
            color: #ffffff;
          }
        }
        .desc {
          margin-top: 6px;
        }
      }
    }
    .rule-text {
      font-size: 18px;
      font-weight: 1000;
      line-height: 32px;
      color: #2a6cf6;
      border-radius: 100px;
      background: #ffffff;
      padding: 4px 32px;
      margin: 20px auto 32px;
      text-align: center;
      width: 220px;
      font-family: Alibaba-PuHuiTi-Bold;
      .fontOverflow();
    }
    .center-rule {
      min-width: 280px;
      max-width: 400px;
      width: 66%;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
  }
  :deep(.el-dialog.is-align-center) {
    border-radius: 16px;
    .el-dialog__title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      color: #262626;
    }
    .dialog-content {
      font-size: 14px;
      line-height: 22px;
      color: #646566;
    }
    .confirm-btn {
      color: #2a6cf6;
      font-size: 16px;
      font-weight: 600;
      border-top: solid 1px rgba(255, 255, 255, 0.5);
      padding-top: 20px;
    }
    &.dialog-dark {
      background-color: #282e3a;
      .el-dialog__title {
        color: #ffffff;
      }
      .dialog-content {
        color: rgba(255, 255, 255, 0.7);
      }
      .confirm-btn {
        color: #ffffff;
        border-top: solid 1px rgba(255, 255, 255, 0.2);
      }
    }
  }
  // :deep(.el-dialog .el-dialog__title) {
  //   font-size: 16px;
  //   font-weight: 600;
  //   line-height: 24px;
  //   text-align: center;
  //   color: #262626;
  // }
  // .dialog-content {
  //   font-size: 14px;
  //   line-height: 22px;
  //   color: #646566;
  //   &.dark {
  //     color: rgba(255, 255, 255, 0.7);
  //   }
  // }
  // .confirm-btn {
  //   color: #2a6cf6;
  //   font-size: 16px;
  //   font-weight: 600;
  //   border-top: solid 1px #eee;
  //   padding-top: 20px;
  //   &.dark {
  //     color: #ffffff;
  //     border-top: solid 1px rgba(255, 255, 255, 0.3);
  //   }
  // }
}
</style>
