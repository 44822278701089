<template>
  <div class="carousel-container-pc">
    <Carousel :autoplay="5000" :wrap-around="showPagination">
      <Slide
        v-for="(item, index) in scheme ? carouseDataLight : carouseDataDark"
        :key="index"
      >
        <img class="carousel-item-image-pc" :src="item.url" alt="" />
        <div class="carousel-item-text-pc">
          <h3
            :class="{ dark: !scheme }"
            :style="
              lang === 'CN'
                ? carouseDataDesc[index].styleH3Cn
                : carouseDataDesc[index].styleH3En
            "
          >
            {{ carouseDataDesc[index].title }}
          </h3>
          <p
            :class="{ dark: !scheme }"
            :style="
              lang === 'CN'
                ? carouseDataDesc[index].stylePCn
                : carouseDataDesc[index].stylePEn
            "
          >
            {{ carouseDataDesc[index].desc }}
          </p>
          <el-button
            class="go-button-pc"
            type="primary"
            tag="a"
            :href="carouseDataDesc[index].url"
            target="_blank"
            :style="{ borderRadius: '100px' }"
          >
            {{ carouseDataDesc[index].button }}
          </el-button>
        </div>
      </Slide>
      <template #addons>
        <Pagination v-if="showPagination" />
      </template>
    </Carousel>
    <Header />
    <!-- <div class="research-header-bg" :class="{ dark: !scheme }"></div> -->
  </div>
</template>

<script>
import { defineComponent, computed, ref, inject, watch } from 'vue'
import { Carousel, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'Carousel',
  components: { Carousel, Slide, Pagination },
})
</script>

<script setup>
import Header from '@/views/components/Header.vue'
const i18n = useI18n()

const carouseDataLight = [
  { url: require('@/assets/img/carousel/pc/bg.png') },
  { url: require('@/assets/img/carousel/pc/bg1.png') },
]

const carouseDataDark = [
  { url: require('@/assets/img/carousel/pc/bg_dark.png') },
  { url: require('@/assets/img/carousel/pc/bg1_dark.png') },
]

const carouseDataDesc = computed(() => {
  return [
    {
      title: i18n.t('carousel.carouselContent1.title'),
      desc: i18n.t('carousel.carouselContent1.descPc'),
      button: i18n.t('carousel.carouselContent1.button'),
      url: 'https://cloud.aigene.org.cn/',
      styleH3Cn: { width: '700px', fontSize: '66px' },
      styleH3En: { width: '820px', fontSize: '40px' },
      stylePCn: { width: '580px', fontSize: '20px' },
      stylePEn: { width: '800px', fontSize: '16px' },
    },
    {
      title: i18n.t('carousel.carouselContent2.title'),
      desc: i18n.t('carousel.carouselContent2.descPc'),
      button: i18n.t('carousel.carouselContent2.button'),
      url: 'https://mp.weixin.qq.com/s/RMAmtdTsV_m5YRl1PQKM5g',
      styleH3Cn: { width: '680px', fontSize: '48px', lineHeight: 'normal' },
      styleH3En: { width: '870px', fontSize: '34px', lineHeight: 'normal' },
      stylePCn: { width: '578px', fontSize: '20px', marginTop: '60px' },
      stylePEn: { width: '800px', fontSize: '16px', marginTop: '40px' },
    },
  ]
})

const scheme = inject('globalScheme')

const lang = inject('globalLang')

const showPagination = computed(() => {
  if (scheme) {
    return carouseDataLight.length > 1 ? true : false
  } else {
    return carouseDataDark.length > 1 ? true : false
  }
})
</script>

<style lang="less" scoped>
@import '~@/assets/styles/common.less';
.carousel-container-pc {
  width: 1440px;
  height: 810px;
  padding: 0px 0px;
  margin: 0 auto;
  position: relative;

  a {
    text-decoration: none; /* 去除默认的下划线 */
    outline: none; /* 去除旧版浏览器的点击后的外虚线框 */
    color: #ffffff; /* 去除默认的颜色和点击后变化的颜色 */
  }

  :deep(.carousel__track) {
    margin: 0px;
  }

  :deep(.carousel__pagination) {
    margin: 0;
    position: absolute;
    bottom: 20px;
    left: 0;
    padding: 16px 24px;
    width: 100%;

    .carousel__pagination-button::after {
      background-color: #ffffff;
      width: 16px;
      height: 4px;
      border-radius: 1px;
    }
    .carousel__pagination-button:hover::after {
      background-color: #409eff;
    }
    .carousel__pagination-button--active::after {
      background-color: #409eff;
      width: 24px;
    }
  }

  .research-header-bg {
    position: absolute;
    bottom: 0;
    height: 80px;
    left: 0;
    width: 100%;
    z-index: 2;
    background-color: #fff;
    border-radius: 60px 60px 0 0;
    // transition: @darktransition;
    &.dark {
      background-color: #17171a;
      // transition: @darktransition;
    }
  }

  .carousel-item-image-pc {
    width: 100%;
    object-fit: cover;
  }

  .carousel-item-text-pc {
    position: absolute;
    text-align: left;
    left: 98px;
    top: 228px;

    h3 {
      text-align: left;
      margin: 0px;
      left: 0px;
      top: 0px;
      width: 660px;
      height: 110px;
      opacity: 1;
      font-family: Alibaba-PuHuiTi-Bold;
      font-size: 66px;
      font-weight: 1000;
      line-height: 110px;
      letter-spacing: 0em;
      font-variation-settings: 'opsz' auto;
      font-feature-settings: 'kern' on;
      color: #283464;
      z-index: 0;
      &.dark {
        color: #ffffff;
      }
    }

    p {
      text-align: left;
      margin: 30px 0px 40px 0px;
      left: 0px;
      top: 126px;
      width: 580px;
      height: 112px;
      opacity: 1;
      font-family: AlibabaPuHuiTi;
      font-size: 20px;
      font-weight: normal;
      line-height: 28px;
      letter-spacing: 0em;
      font-variation-settings: 'opsz' auto;
      font-feature-settings: 'kern' on;
      color: #283464;
      z-index: 1;
      &.dark {
        color: #ffffffb3;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
:deep(.go-button-pc) {
  background: linear-gradient(113deg, #598fff 11%, #1d62f1 96%);
  box-shadow: 4px 4px 10px 0px rgba(42, 108, 246, 0.35);

  border: none;
  padding: 16px 64px; /* 自定义内边距 */
  width: 224px; /* 自定义宽度 */
  height: 66px; /* 维持按钮原始高度 */
  font-family: PingFang SC;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0em;

  transition: transform 300ms ease-in-out; /* 定义过渡效果 */
  transform-origin: center;
}
:deep(.go-button-pc:hover) {
  transform: scale(1.2); /* 鼠标悬停时元素放大1.2倍 */
}
</style>
