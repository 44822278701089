<template>
  <div class="carousel-container-mobile">
    <Carousel :autoplay="5000" :wrap-around="showPagination">
      <Slide v-for="(item, index) in (scheme ? carouseDataLight : carouseDataDark)" :key="index">
        <img class="carousel-item-image-mobile" :src="item.url" alt="" />
        <div class="carousel-item-text-mobile">
          <h3 :class="{ dark: !scheme }" :style="lang === 'CN' ? carouseDataDesc[index].styleH3Cn : carouseDataDesc[index].styleH3En">{{ carouseDataDesc[index].title }}</h3>
          <p :class="{ dark: !scheme }" :style="lang === 'CN' ? carouseDataDesc[index].stylePCn : carouseDataDesc[index].stylePEn">{{ carouseDataDesc[index].desc }}</p>
          <el-button
            class="go-button-mobile"
            type="primary"
            tag="a"
            :href="carouseDataDesc[index].url"
            target="_blank"
            :style="{ borderRadius: '100px' }"
          >
          {{ carouseDataDesc[index].button }}
          </el-button>
        </div>
      </Slide>

      <template #addons>
        <Pagination v-if="showPagination" />
      </template>
    </Carousel>
    <Header />
    <div class="bottom-circle-bg-mb" :class="{ dark: !scheme }"></div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, inject, watch } from 'vue'
import { Carousel, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Carousel',
  components: { Carousel, Slide, Pagination },
})
</script>

<script setup>
import Header from '@/views/components/Header.vue'
const i18n = useI18n();

const carouseDataLight = [
  { url: require('@/assets/img/carousel/mobile/bg.png') },
  { url: require('@/assets/img/carousel/mobile/bg1.png') }
  ]

const carouseDataDark = [
  { url: require('@/assets/img/carousel/mobile/bg_dark.png') },
  { url: require('@/assets/img/carousel/mobile/bg1_dark.png') }
]


const carouseDataDesc = computed(() => {
  return [
    {
      title: i18n.t("carousel.carouselContent1.title"),
      desc: i18n.t("carousel.carouselContent1.descMobile"),
      button: i18n.t("carousel.carouselContent1.button"),
      url: 'https://cloud.aigene.org.cn/',
      styleH3Cn: {left: '39px', width: '300px', height: '42px', fontSize: '30px'},
      styleH3En: {left: '39px', width: '300px', height: '42px', fontSize: '20px'},
      stylePCn: {top: '94px', width: '300px'},
      stylePEn: {top: '94px', width: '300px', marginBottom: '30px'}
    },
    {
      title: i18n.t("carousel.carouselContent2.title"),
      desc: i18n.t("carousel.carouselContent2.descMobile"),
      button: i18n.t("carousel.carouselContent2.button"),
      url: 'https://mp.weixin.qq.com/s/RMAmtdTsV_m5YRl1PQKM5g',
      class: 'carousel-item-text-bg1-mobile',
      styleH3Cn: {left: '26px', width: '340px', height: '68px', fontSize: '24px'},
      styleH3En: {left: '26px', width: '340px', height: '68px', fontSize: '18px'},
      stylePCn: {top: '120px', width: '230px'},
      stylePEn: {top: '120px', width: '340px', marginBottom: '30px'}
    }
  ]
})

const scheme = inject('globalScheme')
const lang = inject('globalLang')

const showPagination = computed(() => {
  if(scheme) {
    return carouseDataLight.length > 1 ? true : false
  }
  else {
    return carouseDataDark.length > 1 ? true : false
  }
})
</script>

<style lang="scss" scoped>
.carousel-container-mobile {
  position: relative;

  a {
    text-decoration: none; /* 去除默认的下划线 */
    outline: none; /* 去除旧版浏览器的点击后的外虚线框 */
    color: #ffffff; /* 去除默认的颜色和点击后变化的颜色 */
  }

  :deep(.carousel__track) {
    margin: 0px;
  }

  :deep(.carousel__pagination) {
    margin: 0;
    position: absolute;
    bottom: 60px;
    left: 0;
    padding: 16px 24px;
    width: 100%;

    .carousel__pagination-button::after {
      background-color: #ffffff;
      width: 16px;
      height: 4px;
      border-radius: 1px;
    }
    .carousel__pagination-button:hover::after {
      background-color: #409eff;
    }
    .carousel__pagination-button--active::after {
      background-color: #409eff;
      width: 24px;
    }
  }

  .carousel-item-image-mobile {
    width: 100%;
    height: 664px;
    object-fit: cover;
  }

  .carousel-item-text-mobile {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 100px;

    h3 {
      text-align: center;
      margin: 0px;
      left: 39px;
      top: 40px;
      width: 300px;
      height: 42px;
      opacity: 1;
      font-family: Alibaba-PuHuiTi-Bold;
      font-size: 30px;
      font-weight: 1000;
      line-height: normal;
      letter-spacing: 0em;
      font-variation-settings: 'opsz' auto;
      font-feature-settings: 'kern' on;
      color: #283464;
      &.dark {
        color: #ffffff;
      }
    }
    p {
      text-align: center;
      margin: 20px 0px 20px 0px;
      left: 20px;
      top: 94px;
      width: 300px;
      height: 36px;
      opacity: 1;
      font-family: AlibabaPuHuiTi;
      font-size: 13px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0em;
      font-variation-settings: 'opsz' auto;
      font-feature-settings: 'kern' on;
      color: #283464;
      &.dark {
        color: #ffffffb3;
      }
    }
  }

  .bottom-circle-bg-mb {
    position: absolute;
    bottom: -2px;
    height: 50px;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: #fff;
    border-radius: 25px 25px 0 0;
    &.dark {
      background-color: #17171a;
    }
  }
}
</style>

<style lang="scss" scoped>
:deep(.go-button-mobile) {
  background: linear-gradient(114deg, #598fff 11%, #1d62f1 96%);
  box-shadow: 4px 4px 10px 0px rgba(42, 108, 246, 0.35);

  border: none;
  padding: 10px 40px; /* 自定义内边距 */
  width: 144px; /* 自定义宽度 */
  height: 44px; /* 维持按钮原始高度 */
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  color: #fff;
}
</style>
