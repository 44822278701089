<template>
  <div class="scientific-exploration-container" :class="{ dark: !scheme }" @mouseenter="animateRender">
    <TitlePanel
      :title="$t('navigator.scientificExploration1') + $t('navigator.scientificExploration2') + $t('navigator.scientificExploration3')"
      titleEn="Scientific Exploration Accelerator: Visualization Service Cloud Platform"
    />
    <div
      v-if="animateStep3"
      class="exploration-content animate__animated animate__fadeInDown"
    >
      <div v-for="(item, index) in explorationContent" :key="index" class="exploration-desc">
        <div class="title" :class="{ dark: !scheme }">
          <div class="bar"></div>
          <span :title="item.title">{{ item.title }}</span>
        </div>
        <div class="desc" :class="{ dark: !scheme }">{{ item.desc }}</div>
      </div>
    </div>
    <div v-else class="exploration-content-empty"></div>
    <div
      v-if="animateStep1"
      class="exploration-wrap animate__animated animate__fadeInDown"
    >
      <img :src="scheme ? bg : bgDark" alt="" />
    </div>
    <div v-else class="exploration-wrap-empty"></div>
    <div v-if="animateStep2" class="btn-wrap" @click="goLink">{{ $t("common.tryButton") }}</div>
    <div v-else class="btn-wrap-empty"></div>
  </div>
</template>

<script setup>
import TitlePanel from '../../components/TitlePanel.vue'
import { onMounted, ref, onUnmounted, inject, computed } from 'vue'
import bg from '@/assets/img/scientificexploration/bg.png'
import bgDark from '@/assets/img/scientificexploration/bg_dark.png'
import { useI18n } from 'vue-i18n';
const i18n = useI18n();

const scheme = inject('globalScheme')
const explorationContent = computed(() => {
  return [
    {
      title: i18n.t("scientificExploration.one.title"),
      desc: i18n.t("scientificExploration.one.desc")
    },
    {
      title: i18n.t("scientificExploration.two.title"),
      desc: i18n.t("scientificExploration.two.desc")
    },
    {
      title: i18n.t("scientificExploration.three.title"),
      desc: i18n.t("scientificExploration.three.desc")
    },
    {
      title: i18n.t("scientificExploration.four.title"),
      desc: i18n.t("scientificExploration.four.desc")
    }
  ]
})

const animateStep1 = ref(false)
const animateStep2 = ref(false)
const animateStep3 = ref(false)

const animateRender = () => {
  function stepOne() {
    setTimeout(() => {
      animateStep1.value = true
      stepTwo()
    })
  }
  function stepTwo() {
    setTimeout(() => {
      animateStep2.value = true
      stepThree()
    }, 300)
  }
  function stepThree() {
    setTimeout(() => {
      animateStep3.value = true
    }, 200)
  }
  stepOne()
}

const onScroll = () => {
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop
  if (scrollTop > 3000 && scrollTop < 4800) {
    animateRender()
    window.removeEventListener('scroll', onScroll)
  }
}

const goLink = () => {
  window.open('https://cloud.aigene.org.cn/')
}

onMounted(() => {
  window.addEventListener('scroll', onScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>

<style scoped lang="less">
.scientific-exploration-container {
  width: 1244px;
  padding: 86px 98px 110px;
  margin: 0 auto;
  position: relative;
  background: #eff4ff; //浅色模式
  &.dark {
    background: #17171a; //深色模式
  }
  .exploration-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 447px;
    height: 447px;
    margin: -700px auto 0;
    img {
      width: 100%;
    }
  }
  .exploration-wrap-empty {
    width: 447px;
    height: 447px;
    margin: -700px auto 0;
  }
  .btn-wrap {
    position: relative;
    background: linear-gradient(113deg, #598fff 11%, #1d62f1 96%);
    box-shadow: 4px 4px 10px 0px rgba(42, 108, 246, 0.35);
    border-radius: 100px;
    height: 66px;
    line-height: 66px;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    width: 224px;
    margin: 0 auto;
    cursor: pointer;
    animation: fadeInDown;
    animation-duration: 1.2s;
    transition: transform 300ms ease-in-out;
    -webkit-transition: transform 300ms ease-in-out;
    z-index: 10;
    color: #ffffff;
    &:hover {
      transform: scale(1.2);
    }
  }
  .btn-wrap-empty {
    height: 66px;
  }
  .exploration-content {
    margin: 172px auto 204px;
    width: 1110px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .exploration-desc {
      width: 33.5%;
      height: 180px;
      .title {
        font-size: 24px;
        font-weight: 800;
        line-height: 32px;
        color: #283464;
        display: flex;
        align-items: center;
        font-family: Alibaba-PuHuiTi-Bold;
        .bar {
          background: linear-gradient(167deg, #598fff 0%, #1d62f1 113%);
          width: 6px;
          height: 18px;
          border-radius: 100px;
          margin-right: 8px;
        }
        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        &.dark {
          color: rgba(255, 255, 255, 1);
        }
      }
      .desc {
        color: #646566;
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        margin: 10px 0 90px 14px;
        width: 320px;
        &.dark {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
  .exploration-content-empty {
    height: 360px;
    margin: 172px auto 204px;
  }
}
</style>
