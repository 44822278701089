<template>
  <div
    :class="
      scheme
        ? 'large-model-container-pc large-model-container-pc-light'
        : 'large-model-container-pc large-model-container-pc-dark'
    "
    @mouseenter="animateStep1 = true"
  >
    <TitlePanel :title="$t('navigator.largeModel')" titleEn="Multimodal AI Large Model" />
    <div v-if="animateStep1" class="models-row-pc">
      <div class="models-row-1-pc">
        <div class="layer-0 models-col-pc" :class="{ dark: !scheme }">
          <div class="models-col-1-pc">
            <img class="layer-2" :src="sequence" alt="" />
            <span class="layer-1"  :class="{ dark: !scheme, en: lang !== 'CN' }">
              {{ $t('largeModel.sequence.title') }}
            </span>
            <span class="layer-1" :class="{ dark: !scheme, en: lang !== 'CN' }">
              {{ $t('largeModel.sequence.desc1') }}<br/>{{ $t('largeModel.sequence.desc2') }}
            </span>
          </div>
        </div>

        <div class="layer-0 models-col-pc" :class="{ dark: !scheme }">
          <div class="models-col-1-pc">
            <img class="layer-2" :src="omics" alt="" />
            <span class="layer-1" :class="{ dark: !scheme, en: lang !== 'CN' }">
              {{ $t('largeModel.omics.title') }}
            </span
            >
            <span class="layer-1" :class="{ dark: !scheme, en: lang !== 'CN' }">
              {{ $t('largeModel.omics.desc') }}
            </span
            >
          </div>
        </div>

        <div class="layer-0 models-col-pc" :class="{ dark: !scheme }">
          <div class="models-col-1-pc">
            <img class="layer-2" :src="interact" alt="" />
            <span class="layer-1" :class="{ dark: !scheme, en: lang !== 'CN' }">
              {{ $t('largeModel.interact.title') }}
            </span
            >
            <span class="layer-1" :class="{ dark: !scheme, en: lang !== 'CN' }">
              {{ $t('largeModel.interact.desc') }}
            </span
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else class="models-row-empty-pc"></div>
  </div>
</template>

<script>
import TitlePanel from '../../components/TitlePanel.vue'
import { defineComponent, ref, onMounted, onUnmounted, inject } from 'vue'

export default defineComponent({
  name: 'LargeModel',
  components: { TitlePanel },
})
</script>

<script setup>
const sequence = require('@/assets/img/largemodel/sequence.png')
const interact = require('@/assets/img/largemodel/interact.png')
const omics = require('@/assets/img/largemodel/omics.png')

const scheme = inject('globalScheme')
const lang = inject('globalLang')

const animateStep1 = ref(false)

const onScroll = () => {
  const showWidth =
    document.documentElement.clientWidth || document.body.clientWidth
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop

  if (showWidth < 1200) {
    if (scrollTop > 2640 && scrollTop < 3550) {
      animateStep1.value = true
      window.removeEventListener('scroll', onScroll)
    }
  } else {
    if (scrollTop > 2500 && scrollTop < 3700) {
      animateStep1.value = true
      window.removeEventListener('scroll', onScroll)
    }
  }
}

onMounted(() => {
  window.addEventListener('scroll', onScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>

<style scoped lang="less">
.large-model-container-pc-light {
  background-color: #ffffff; // 浅色模式
}
.large-model-container-pc-dark {
  background-color: #17171a; // 深色模式
}
.large-model-container-pc {
  width: 1244px;
  height: 638px;
  padding: 86px 98px;
  margin: 0 auto;
  position: relative;
  background-image: url('../../../assets/img/largemodel/bg_buttom_left.png'),
    url('../../../assets/img/largemodel/bg_top_right.png');
  background-size: 1000px, 1070px;
  background-position: left -265px bottom -440px, right -450px top -450px;
  background-repeat: no-repeat;

  .models-row-empty-pc {
    display: block;
    margin: 100px 0px 70px 58px;
    height: 353px;
  }

  .models-row-pc {
    display: block;
    margin: 100px 0px 70px 58px;
    .models-row-1-pc {
      display: flex;
      justify-content: space-around;

      .models-col-pc {
        display: flex;
        width: 320px;
        height: 411px;
        border: 1px solid #dbedff;
        background: linear-gradient(143deg, #f7fbff 68%, #ecf5ff 101%);
        border-radius: 16px;
        opacity: 1;
        &.dark {
          background: #282e3a;
          border: none;
        }

        .models-col-1-pc {
          margin-left: 30px;
          padding: 32px 0;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: space-around;
        }

        img {
          height: 150px;
          width: 150px;
        }

        span {
          margin-top: 40px;
          font-size: 32px;
          font-weight: 800;
          line-height: normal;
          text-align: left;
          width: 260px;
          height: 45px;
          opacity: 1;
          font-family: Alibaba-PuHuiTi-Bold;
          letter-spacing: 0em;
          color: #283464;
          font-variation-settings: 'opsz' auto;
          font-feature-settings: 'kern' on;
          &.en {
            width: 280px;
            font-size: 20px;
          }
          &.dark {
            color: #ffffff;
          }

          &:last-child {
            margin-top: 0px;
            font-size: 18px;
            width: 260px;
            height: 56px;
            font-weight: normal;
            font-family: PingFang SC;
            color: #646566;
            &.en {
              width: 280px;
              font-size: 14px;
            }
            &.dark {
              color: #ffffffb3;
            }
          }
        }
      }
    }
  }

  .layer-0 {
    animation-name: anim-layer-0;
    animation-timing-function: linear;
    animation-duration: 600ms;
    animation-fill-mode: both;
    animation-delay: 0s;
  }

  .layer-1 {
    animation-name: anim-layer-1;
    animation-timing-function: linear;
    animation-duration: 500ms;
    animation-fill-mode: both;
    animation-delay: 100ms;
  }
  .layer-2 {
    animation-name: anim-layer-2;
    animation-timing-function: linear;
    animation-duration: 600ms;
    animation-fill-mode: both;
    animation-delay: 0s;
  }

  @keyframes anim-layer-0 {
    0% {
      transform: translateY(-90px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  @keyframes anim-layer-1 {
    0% {
      transform: translateY(-90px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  @keyframes anim-layer-2 {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}
</style>
