export default {
  common: {
    centerName: 'Research Center for Life Science Computing of Zhejiang Lab',
    tryButton: 'Try it now',
    copySuccess: 'Copy success!',
    copyFail: 'Copy failed!'
  },
  navigator: {
    researchDirection: 'Research Direction',
    coreBenefits: 'Core Benefits',
    aboutUs: 'About Us',
    massiveDataset: 'Massive Dataset',
    computingTools: 'Diverse Computing Tools',
    largeModel: 'Multimodal AI Large Model',
    scientificExploration1: 'Scientific Exploration Accelerator',
    scientificExploration2: ': ',
    scientificExploration3: 'Visualization Service Cloud Platform',
    result: 'Papers and Patents',
    partner: 'Cooperative Partner',
    contact: 'Contact Us',
    copyRight: '版权'
  },
  carousel: {
    carouselContent1: {
      title: 'Open Platform for Life Science Computing',
      descPc: 'Provide a one-stop cloud-intelligence productization solution using the integration of IT (computing science) and BT (life science). Relying on high-quality wet experiment datasets and algorithms, and supported by a comprehensive digital platform and R&D system, continuously develop more efficient, faster, and more accurate computational tools to support industrial upgrading.',
      descMobile: 'Using the integration of IT (computing science) and BT (life science) to provide a one-stop cloud-intelligence product solution',
      button: 'Try it now'
    },
    carouselContent2: {
      title: "Latest Achievement: “Tristate Gate” Genetic Circuit Enables Cells to turn on Intelligent Computations",
      descPc: 'Wang Hui from the Research Center for Life Science Computing of Zhejiang Lab, as a co-corresponding author, collaborated with a joint research team from Zhejiang University, National University of Defense Technology, and Westlake University to publish a research paper titled “Multi-layered Computational Gene Networks by Engineered Tristate Logics” in Cell.',
      descMobile: 'Wang Hui from the Research Center for Life Science Computing of Zhejiang Lab, as a co-corresponding author, published research findings in Cell.',
      button: 'View Details'
    },
    platformTitle: 'Open Platform for Life Science Computing',
    platformDescPc: 'Provide a one-stop cloud-intelligence productization solution using the integration of IT (computing science) and BT (life science). Relying on high-quality wet experiment datasets and algorithms, and supported by a comprehensive digital platform and R&D system, continuously develop more efficient, faster, and more accurate computational tools to support industrial upgrading.',
    platformDescMobile: 'Using the integration of IT (computing science) and BT (life science) to provide a one-stop cloud-intelligence product solution'
  
  },
  researchDirection: {
    direction1: 'Disease Diagnosis and Treatment',
    direction2: 'Human Simulation',
    direction3: 'Gene Therapy',
    direction4: 'Functional Protein R&D',
    direction5: 'Nucleic Acid Detection',
    direction6: 'Disease Target Regulation',
    researchBaseName1: {
      name: 'Large Model',
      desc: 'Designing pre-trained models with high generalization capabilities to enhance the accuracy of multimodal life science data characterization for autonomous scientific discovery through intelligent body technology'
    },
    researchBaseName2: {
      name: 'Data',
      desc: 'Achieve standardized intelligent integration, efficient circulation, and secure control of multi-source and multi-modal life science data'
    },
    researchBaseName3: {
      name: 'Computing Toolkit',
      desc: 'Develop innovative computational methods for screening, generating, and optimizing biomolecules to build a digital twin system of the human body.'
    },
    centerDogma: 'The Material Basis and Regulation of the Central Dogma',
    gotIt: 'I understand'
  },
  massiveDataset: {
    sequence: {
      title: 'Sequence',
      desc1: 'Analysis and study of DNA, RNA and protein sequences to investigate their biological functions',
      unit: 'bill.',
      desc2: 'Protein Sequence',
      desc3: 'Data Volume'
    },
    omics: {
      title: 'Omics',
      desc1: 'The training data covers genomics, transcriptomics, proteomics, metabolomics, epigenetics data, mutation data and other multi-omics data;',
      desc2: 'Covers nucleic acids and protein sequences of nearly a hundred species.'
    },
    interaction: {
      title: 'Interaction',
      desc1: 'Covers known and predicted interaction data between proteins, nucleic acids, and small molecules.',
      desc2: 'These interactions include direct (physical) and indirect (functional) associations; they come from computational predictions, knowledge transfer between organisms, and interactions aggregated from other publicly databases.',
      desc3: 'Involves protein and gene interactions, chemical interactions, and post-translational modifications in major model organism species.'
    },
    literature: {
      title: 'Literature',
      desc1: 'Track and detect cutting-edge developments in life sciences',
      desc2: 'Build the full pipeline for literature information extraction',
      desc3: 'Enhance information on protease experimental activity',
      desc4: 'Efficiently support scientific research'
    }
  },
  computingTools: {
    protein: {
      name1: 'Protein Structure Prediction',
      name2: 'Protein Sequence Optimization',
      name3: 'Binding Site Prediction',
      name4: 'High-Throughput Screening'
    },
    gene: {
      name1: 'Nucleic Acid Sequence Phylogenetic Tree',
      name2: 'RNA Structure Prediction'
    },
    protogene: {
      name1: 'Protein Interaction Prediction',
      name2: 'ncRNA-Protein Interaction Prediction'
    },
    sgrna: {
      name1: 'Intelligent sgRNA Design'
    },
  },
  largeModel: {
    sequence: {
      title: 'Sequence Pre-trained Large Model',
      desc1: 'Protein language large model',
      desc2: 'Biological macromolecular sequences'
    },
    omics: {
      title: 'Omics Pre-trained Large Model',
      desc: 'Genomics, transcriptomics, proteomics, metabolomics, and spatial omics'
    },
    interact: {
      title: 'Interactive Pre-trained Large Model',
      desc: 'Protein-small molecule interactions guide protein engineering and drug design'
    },
  },
  scientificExploration: {
    one:{
      title: 'A platform to better understand the demands of scientists',
      desc: 'Based on scientists and biochemical analysts, we provide customized development services to schools, enterprises, and research institutions.',
    },
    two: {
      title: 'Both data sharing and privacy protection',
      desc: 'A carefully designed permission management system that allows secure data sharing between different organizations and teams without physical data transfer, while ensuring privacy protection.',
    },
    three: {
      title: 'AI and big data-driven precision prediction',
      desc: 'The platform consists of billions of protein sequences and massive omics data, utilizing our self-developed foundational large models to deliver more precise and effective predictions.',
    },
    four: {
      title: 'Visual, efficient, and automated computation',
      desc: 'The cloud service platform available online allows you to customize tasks as needed and run them in the background, making it easy to obtain calculation results.',
    }
  },
  result: {},
  partner: {
    desc: 'Covering high-level universities and innovative enterprises'
  },
  contact: {
    copyEmail: 'Click to copy email',
    add: 'Building 11, West Zone, Phase I, Zhejiang Lab, Kechuang Avenue, Zhongtai Sub-District, Yuhang District, Hangzhou, Zhejiang Province, China',
    toMap: 'Jump to Baidu map',
    learnMore: 'Follow the public account of "Zhejiang Lab" for more information'
  },
  copyRight: {}
}